<template>
  <div class="transportations">
    <div class="transportations__wrapper">

      <div class="transportations__head">
        <button class="transportations__back" @click="$router.back()"><i class="fas fa-arrow-left"></i></button>
        <h1 class="transportations__title">
          <span class="ml-1">Регистрации в ЗО</span>
        </h1>
      </div>


      <div class="transportations__loader-wrapper"
           v-if="loading">
        <div class="transportations__loader-content">
          <div class="transportations__loader-circle"></div>
          <div class="transportations__loader-text">Подождите...</div>
        </div>
      </div>

      <div  class="mx-1 mb-3">
        <h3>{{ user.uf_name }} | {{ user.uf_contract }}</h3>
      </div>

      <div class="transportations__filter" v-if="!loading">
        <div class="transportations__filter-content">
          <input placeholder="Номер перевозки" v-model="filter.registration"
                 class="transportations__input">

        </div>
        <div class="transportations__filter-content">
          <input placeholder="Номер Т/С" v-model="filter.regnum"
                 class="transportations__input">

        </div>
        <div class="transportations__filter-content">
          <input placeholder="ФИО водителя" v-model="filter.driver"
                 class="transportations__input">
        </div>
        <div class="transportations__filter-content">
          <select v-model="filter.checkpoint" class="transportations__input">
            <option value="" selected disabled>Зона ожидания</option>
            <option value="Григоровщина">Григоровщина</option>
            <option value="Брест">Брест</option>
            <option value="Урбаны">Урбаны</option>
            <option value="Котловка">Котловка</option>
            <option value="Берестовица">Берестовица</option>
            <option value="Бенякони">Бенякони</option>
            <option value="Каменный Лог">Каменный Лог</option>

          </select>
        </div>

        <div class="transportations__filter-content">
          <div class="transportations__filter-content">
            <select v-model="filter.status" class="transportations__input">
              <option value="" selected disabled>Статус</option>
              <option value="Новая">Новая</option>
              <option value="Завершена">Завершена</option>
              <option value="Отменена">Отменена</option>
            </select>
          </div>
        </div>
        <div class="transportations__filter-content">
          <DatePicker v-model="filter.registrationDate" :format="'DD.MM.YYYY'" ref="end"
                      class="transportations__input"
                      lang="ru"
                      placeholder="Дата"
                      :value-type="'format'"
                      :input-class="'dark:bg-gray-700 outline-none  bg-gray-200 dark:text-white text-black  w-full'"
                      :popup-class="'dark:bg-gray-700'"></DatePicker>
        </div>

        <div class="transportations__filter-content">
          <button class="transportations__filter-button transportations__filter-button_color_green"
                  @click="filterItems">
            Поиск
          </button>
        </div>
        <div class="sm:w-full lg:w-1/8">
          <button class="transportations__filter-button transportations__filter-button_color_blue"
                  @click="dropFilter">
            Сбросить
          </button>
        </div>

      </div>

      <div class="transportations__table-wrapper">

        <table
          class="transportations__table"
          v-if="itemsModified.length">
          <thead class="transportations__table-headings">
          <th class="transportations__table-heading" @click="sort('id')">№
            <span class="inline-block mx-1" :class="{
                            'visible':sorting.sort !== '' && sorting.by === 'id',
                            'visible transform  rotate-0':sorting.sort === 'desc' && sorting.by === 'id',
                            'visible transform   rotate-180':sorting.sort === 'asc' && sorting.by === 'id',
                            'hidden':sorting.by !== 'id'
                        }"><i class="fas fa-arrow-down "></i></span>
          </th>
          <th class="transportations__table-heading" v-for="header in headers" :key="header.value"
              @click="sort(header.value)">{{ header.name }}
            <span class="inline-block mx-1" :class="{
                            'visible':sorting.sort !== '' && sorting.by === header.value,
                            'visible transform rotate-0':sorting.sort === 'desc' && sorting.by === header.value,
                            'visible transform rotate-180':sorting.sort === 'asc' && sorting.by === header.value,
                            'hidden':sorting.by !== header.value
                        }"><i class="fas fa-arrow-down "></i></span>
          </th>
          </thead>
          <tbody class="transportations__table-body">
          <tr class="transportations__table-row"
              v-for="(item, idx) in itemsModified" :key="idx">
            <td class="p-3">{{ idx + 1 }}</td>
            <td class="p-3" :class="{'text-green-400': header.value === 'status' && item[header.value]==='Завершена',
                             'text-yellow-400':header.value === 'status' && item[header.value]==='Новая',
                              'text-gray-400':header.value === 'status' && item[header.value]==='Отменена',
                               'text-blue-400':header.value === 'status' && item[header.value]=== 'Зарезервировано'
            }"
                :key="header.value" v-for="header in headers">{{ item[header.value]  }}
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else-if="!loading">
          <h3 class="transportations__empty">
            Список перевозок пуст
            <g-emoji>😥</g-emoji>
          </h3>
        </div>

      </div>

      <Pagination v-if="items.length" :hideNext="hideNext" dashboard namespace="clientRegistrations"></Pagination>

    </div>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Pagination from '@/components/common/Pagination'
import TableSortingMixin from '@/mixins/tableSorting'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "RegistrationsAdmin",
  components: {Pagination, DatePicker},
  mixins: [TableSortingMixin],
  data: () => ({
    headers: [
      {name: 'Номер регистрации', value: 'registration'},
      {name: 'Дата регистрации', value: 'registrationDate'},
      {name: 'Зона ожидания', value: 'checkpoint'},
      {name: 'Номер Т/С', value: 'regnum'},
      {name: 'ФИО водителя', value: 'driver'},
      {name: 'Сумма', value: 'sum'},
      {name: 'Статус', value: 'status'},
    ],
    filter: {
      registration: '',
      regnum: '',
      driver: '',
      status: '',
      registrationDate:"",
      checkpoint:""
    },
    user: {},

  }),
  computed: {
    ...mapGetters({
      items: 'clientRegistrations/registrations',
      perPage: 'clientRegistrations/perPage',
      loading: 'clientRegistrations/loading'
    }),
    hideNext() {
      return this.items.length === 0 || this.items.length < this.perPage;
    },
    itemsModified(){
      return this.items.map(i => {
        return {
          ...i,
          status: i.status || 'Зарезервировано'
        }
      })
    }
  },
  created() {
    this.$store.dispatch('admin/getContractSingle', {id:this.$route.params.id}).then(res => {
      this.user = res.data.data
    }).catch(err => {
      this.$notify({
        title: 'Ошибка',
        text: err.response.data.message,
        type: 'error'
      })
    })
    this.$store.commit('clientRegistrations/selectUser', this.$route.params.id)
  },
  mounted() {
    document.addEventListener('keypress', this.pressEnter)
    this.$watch('sorting', function (newVal) {
      this.$store.commit('clientRegistrations/setSorting', {sort: newVal.sort, sortBy: newVal.by})
    }, {
      deep: true, immediate: true
    })
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.pressEnter)
    this.$store.commit('clientRegistrations/unset')
  },
  methods: {
    pressEnter() {
      if (event.keyCode === 13) {
        this.filterItems()
      }
    },
    filterItems() {
      this.$store.commit('clientRegistrations/setFilter', this.filter)
    },
    dropFilter() {
      this.$store.commit('clientRegistrations/unset')
      this.$store.commit('clientRegistrations/selectUser', this.$route.params.id)
      this.$store.dispatch('clientRegistrations/fetch')
    },

  },

}
</script>

<style scoped>
.transportations {
  @apply w-full mx-auto xl:container my-10
}

.transportations__wrapper {
  @apply sm:my-10 xl:mx-10
}
.transportations__head{
  @apply mb-5 flex
}
.transportations__back{
  @apply text-blue-500
}
.transportations__title {
  @apply dark:text-white text-gray-800 text-2xl  w-full flex justify-between
}

.transportations__loader-wrapper {
  @apply absolute top-0 left-0 flex h-full min-h-screen w-full bg-black opacity-80 z-10 justify-center
}

.transportations__loader-content {
  @apply flex-col flex justify-center
}

.transportations__loader-circle {
  @apply w-12 h-12 border-4 border-blue-600 rounded-full loader mx-auto flex
}

.transportations__loader-text {
  @apply text-lg block
}

.transportations__filter {
  @apply flex sm:flex-col xl:flex-row mx-1 mb-5 h-full
}

.transportations__filter-content {
  @apply sm:w-full mr-0.5
}

.transportations__input {
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent
  bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white
}

.transportations__filter-button {
  @apply px-4 py-2.5 mt-2 w-full text-white
}

.transportations__filter-button_color_blue {
  @apply bg-blue-400
}

.transportations__filter-button_color_green {
  @apply bg-green-400
}
.transportations__table-wrapper{
  @apply overflow-x-scroll overflow-y-scroll max-h-128  shadow-md
}
.dark .transportations__table-wrapper{
  box-shadow: none;
}
.transportations__table{
  @apply text-left w-full border-collapse  dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm
}
.transportations__table-headings{
  @apply dark:bg-gray-800 bg-white text-gray-500
}
.transportations__table-heading{
  @apply p-3 select-none
}
.transportations__table-body{
  @apply dark:text-gray-400 text-gray-600
}
.transportations__table-row{
  @apply dark:bg-gray-800 bg-white  dark:hover:bg-gray-700 hover:bg-gray-300
}
.transportations__empty{
  @apply text-2xl text-center w-full p-10
}
::-webkit-scrollbar {
  display: block;
  overflow: auto;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: lightskyblue;
}


select {
  -webkit-appearance: none;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>